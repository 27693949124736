/* global globalVars */
import {stringify} from 'himalaya';
/**
 * The additional wrapping elements (with classname `cls-min-size` and `data-cls-target` attribute)
 * are required by the library `@buzzfeed/cls` to report and display accurrate height data,
 * enabling the HTML response to have a min height for the embed already reserved. This helps
 * reduce the Cumulative Layout Shift (CLS) score of the overall page. If the consuming app does
 * not have the `@buzzfeed/cls` integrated and configured then the embed will gracefully fallback
 * with default visual behavior.
 * More info in the library readme:
 * https://github.com/buzzfeed/mono/blob/master/packages/frontend/cls/README.md
 */
globalVars.rendering = `
  <div class="cls-min-size">
    <div data-cls-target>
      ${stringify(globalVars.data.web)}
    </div>
  </div>`;
